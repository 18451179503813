<template>
    <Navbar></Navbar>
    <router-view :key="$route.fullPath"></router-view>
</template>

<script>
    import { useRouter } from 'vue-router'
    import Navbar from './components/Navbar.vue';

    export default {
    name: 'App',
    components: {
        Navbar
    },
    setup() {
        const router = useRouter()
        return { router }
    }
}
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #fff;
    }

    .navbar {
        padding: 30px;
    }

    .navbar a {
        font-weight: bold;
        color: #fff;
    }

    .navbar a.router-link-exact-active {
        color: hsl(var(--s));
    }
</style>
