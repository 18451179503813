import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
	},

	{
		path: '/servicios/domotica/iluminacion',
		name: 'iluminacion',
		component: () => import(/* webpackChunkName: "iluminacion" */ '../views/domoticaViews/IluminacionView.vue')
	},

	{
		path: '/servicios/domotica/sombreado',
		name: 'sombreado',
		component: () => import(/* webpackChunkName: "sombreado" */ '../views/domoticaViews/SombreadoView.vue')
	},

	{
		path: '/servicios/domotica/climatizacion',
		name: 'climatizacion',
		component: () => import(/* webpackChunkName: "climatizacion" */ '../views/domoticaViews/ClimatizacionView.vue')
	},

	{
		path: '/servicios/domotica/audio-multizona',
		name: 'audio-multizona',
		component: () => import(/* webpackChunkName: "audio-multizona" */ '../views/domoticaViews/AudioMultizonaView.vue')
	},

	{
		path: '/servicios/domotica/eficiencia-energetica',
		name: 'eficiencia-energetica',
		component: () => import(/* webpackChunkName: "eficiencia-energetica" */ '../views/domoticaViews/EficienciaEnergeticaView.vue')
	},

	{
		path: '/servicios/domotica/seguridad',
		name: 'seguridad',
		component: () => import(/* webpackChunkName: "seguridad" */ '../views/domoticaViews/SeguridadView.vue')
	},

	{
		path: '/servicios/domotica/accesos',
		name: 'accesos',
		component: () => import(/* webpackChunkName: "accesos" */ '../views/domoticaViews/AccesosView.vue')
	},

	{
		path: '/servicios/domotica/jardineria-autonoma',
		name: 'jardineria-autonoma',
		component: () => import(/* webpackChunkName: "jardineria-autonoma" */ '../views/domoticaViews/JardineriaAutonomaView.vue')
	},

	{
		path: '/servicios/domotica/piscinas',
		name: 'piscinas',
		component: () => import(/* webpackChunkName: "piscinas" */ '../views/domoticaViews/PiscinasView.vue')
	},

	{
		path: '/servicios/domotica',
		name: 'domotica',
		component: () => import(/* webpackChunkName: "domotica" */ '../views/DomoticaView.vue')
	},

	{
		path: '/servicios/fotovoltaica',
		name: 'fotovoltaica',
		component: () => import(/* webpackChunkName: "fotovoltaica" */ '../views/FotovoltaicaView.vue')
	},

	{
		path: '/servicios/cctv',
		name: 'cctv',
		component: () => import(/* webpackChunkName: "cctv" */ '../views/CctvView.vue')
	},

	{
		path: '/servicios/electricidad',
		name: 'electricidad',
		component: () => import(/* webpackChunkName: "electricidad" */ '../views/ElectricidadView.vue')
	},

	{
		path: '/servicios/informatica',
		name: 'informatica',
		component: () => import(/* webpackChunkName: "informatica" */ '../views/InformaticaView.vue')
	},

	{
		path: '/servicios',
		name: 'servicios',
		component: () => import(/* webpackChunkName: "servicios" */ '../views/ServiciosView.vue')
	},

	{
		path: '/about',
		name: 'About',
		component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	},

	{
		path: '/contacto',
		name: 'contacto',
		component: () => import(/* webpackChunkName: "contacto" */ '../views/ContactoView.vue')
	},

	{
		path: '/politica-privacidad',
		name: 'politicaPrivacidad',
		component: () => import(/* webpackChunkName: "politicaPrivacidad" */ '../views/PoliticaPrivacidad.vue')
	},

	{
		path: '/:catchAll(.*)', // Ruta para capturar todo lo demás
		name: 'NotFound',
		component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
	},

]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior (to, from, savedPosition) {
		// siempre desplazar el scroll hacia arriba al cambiar de ruta
		return { top: 0 }
	}
})

export default router
